<template>
    <v-container class="fadeInFast">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>

        <HeadlineTableView
                :icon="'mdi-calendar'"
                :headline="'Termine'"
                :button-icon="'mdi-calendar'"
                :button-text="'Neuer Termin'"
                @buttonClick="openDialogCreateEvent()"
        ></HeadlineTableView>
        <button type="button" class="btn btn-primary" v-if='!authorized' @click="handleAuthClick">Login</button>
        <button type="button" class="btn btn-primary" v-if='authorized' @click="handleSignOutClick">Sign Out</button>
        <button type="button" class="btn btn-primary" v-if='authorized' @click="getEvents">Get Events</button>
        <v-card flat class="rounded-xl pa-2 pa-sm-4">
            <CalendarComponent
                    v-if="loaded"
                    :events="events"
                    @deleteEvent="deleteEvent($event)"
            ></CalendarComponent>
            <Spinner v-else></Spinner>

            <CreateEvent
                    :dialog="dialog"
                    @hideDialog="hideDialog"
            ></CreateEvent>
        </v-card>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";

const CalendarComponent = () => import("@/components/entities/calendar/Calendar");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const Message = () => import("@/components/generalUI/Message");
const CreateEvent = () => import("@/components/dialog/createDialogs/CreateEvent");
const Spinner = () => import("@/components/generalUI/Spinner");
// Client ID and API key from the Developer Console
const CLIENT_ID = "Your_application_client_id";
const API_KEY = "Your_application_api_key";
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';
export default {
    name: "Calendar",
    components: {
        CalendarComponent,
        HeadlineTableView,
        Message,
        CreateEvent,
        Spinner
    },
    computed: {
        ...mapGetters('auth', {
            user: 'user',
        }),
        ...mapGetters('calendar', {
            events: 'events',
        }),
    },
    created() {
        // eslint-disable-next-line no-undef
        this.api = gapi;
        this.handleClientLoad();
    },
    data() {
        return {
            dialog: false,
            message: msgObj(),
            datetime: null,
            loaded: false,
            api: undefined,
            authorized: false

        }
    },
    mounted() {

        window.scrollTo(0, 0)
        this.$store.dispatch('auth/checkLogin', {}).then((res) => {
            this.$store.dispatch('calendar/getEvents', {
                uid: res
            }).then(() => {
                this.loaded = true
            }).catch((err) => {
                this.message = error(err)
            })
        })

    },
    initClient() {
        let vm = this;
        vm.api.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES
        }).then(() => {
            vm.api.auth2.getAuthInstance().isSignedIn.listen(vm.authorized);
        });
    },
    methods: {

        handleClientLoad() {
            this.api.load('client:auth2', this.initClient);
        },
        handleAuthClick() {
            console.log("test")
            console.log(this.api.auth2.getAuthInstance())
            Promise.resolve(this.api.auth2.getAuthInstance().signIn())
                .then(() => {
                    this.authorized = true;
                });
        },
        openDialogCreateEvent() {
            this.dialog = true
        },
        hideDialog() {
            this.dialog = false
        },
        deleteEvent(payload) {
            console.log(payload)
            this.$store.dispatch('calendar/deleteEvent', {
                eventId: payload.eventId,
                uid: this.user.id
            }).then(() => {
                    this.message = success("Termin wurde erfolgreich gelöscht.")
                }
            ).catch((err) => {
                this.message = error(err)
            })
        },
    }
}
</script>

<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)

  > .v-card__text
    color: #000
</style>
<style scoped>
.border {
    border-right: 1px solid grey
}
</style>
